export const TRANSLATIONS_ZH = {
  addnew: '添加',
  assets: '资产',
  company: '公司',
  current: '现',
  price: '现价',
  trend: '趋势',
  editlist: '修改',
  mywatchlist: '自选池',
  pleaselogintoseeyourwatchlist: '请登录查阅你的观察股列表',
  priceforecast: '预测价',
  setupyourwatchlistbyaddingstocks: '加入新股，建立你的观察股列表！',
  stopedit: '结束编辑',
  stocks: '股票',
  options: '期权',
  symbol: '代码',
  unusualactivity: '异常活动',
  welcome: '欢迎使用本教程',
  watchlist: '自选池',
  forecast: '预测',
  months: '个月',
  month: '个月',
  changeinprice: '涨跌',
  volumechange: '成交量%',
  volume: '成交量',
  removestock: '将',
  fromwatchlist: '从观察股列表中移除',
  remove: '移除',
  stocksearch: '股票搜索',
  searchforanystock: '通过公司名或股票代码搜索股票',
  clickonanyresult: '点击任一结果将其加入你的观察股列表',
  enterstocksymbol: '输入股票代号或公司名',
  searchabove: '搜索以上内容查看结果',
  noresultsfound: '查找无结果，请尝试不同的代码',
  dashboard: '概要',
  watchlistTab: '自选',
  accounts: '账户',
  trade: '交易',
  portfolioamount: '总资产',
  investments: '投资金额',
  netchange: '净收益',
  portfolioallocations: '投资组合配置',
  cash: '现金',
  crypto: '加密货币',
  equity: '股票',
  fixedincome: '固定收益',
  other: '其他',
  myinvestments: '我的投资',
  shares: '数量',
  costbasis: '成本价',
  marketvalue: '市价',
  pl: '盈亏',
  showless: '显示更少',
  showmore: '显示更多',
  investingthemes: '投资板块',
  top30: 'TOP 30',
  etfs: '指数基金',
  ev: '电动车',
  energy: '能源',
  finance: '金融',
  semis: '半导体',
  tech: '科技',
  language: '语言',
  allocations: '仓位分配',
  search: '搜索',
  unusual: '异常',
  stock: '股票',
  robo: '自动交易',
  aistock: 'AI股票交易服务',
  aicrypto: 'AI加密货币交易服务',
  // onemonth: '1月',
  ytd: '年初至今',
  oneyear: '1年',
  year: '年',
  years: '年',
  all: '全部',
  transferfunds: '转账',
  accountactivity: '账户动态',
  documents: '文档',
  equityvalue: '资产价值',
  netpl: '净盈亏',
  pendingtransfers: '待办转账',
  pendingorders: '待办定单',
  recurringbuys: '定期买入',
  nextbuy: '下次买入',
  // amount: '金额',
  stop: '停止',
  edit: '修改',
  summary: '概览',
  gainloss: '盈亏',
  availableshares: '持有数量',
  buyingpower: '可用金额',
  marketorder: '市价委托',
  limitorder: '限价委托',
  ordertype: '委托类型',
  approxamount: '预估股数',
  youcannotsellmorethanyouown: '无法卖出未持有的数量',
  buy: '买入',
  estimatedquantity: '预估数量',
  estimatedcommission: '预估手续费',
  estimatedtotal: '预估总金额',
  submittrade: '提交',
  from: '从',
  to: '到',
  depositamount: '存入总额',
  amount: '资金总额',
  withdrawalamount: '提款总额',
  youcanonlydeposit5000perday:
    '对于您的所有DriveWealth账户，每日存入总资金不能超过$5,000，每日存入总次数不能超过5次',
  submit: '提交',
  wirewithdraw: '电汇提款',
  tradeconfirmation: '交易确认',
  accountstatements: '账户报表',
  taxdocuments: '税务报表',
  pleaseenteramountgreaterthan0: '请输入大于零的金额 ',
  notenoughbuyingpower: '可用金额不足',
  pleaseentersharesgreaterthan0: '请输入大于零的股数',
  onlymarketordersaresupportedwithfractionalshares: '只有市价单支持',
  selectanaccount: '选择一个账户',
  quantity: '成交股数',
  filledprice: '成交价',
  amount: '成交金额',
  cannotenterfractionalsharesforlimitorder: '限价委托不支持零星股',
  limitordersaregooduntilnextmarketclose: '限价委托有效时间为下一个闭市时间前',
  somethingwentwrong: '出现异常，请稍后重试',
  areyousureyouwanttocancelthisorder: '委托将取消，请确认',
  confirmation: '确认',
  notsupportedfortrading: '不支持交易',
  approxnumberofshares: '预估股数',
  approxtotalamount: '预估总金额',
  amountcannotbegreaterthan5000: '金额不能超过5000',
  transfersarenotavailableatthistime: '目前无法执行转账操作',
  transfersareavailabefrom430to130:
    '转账窗口为美国东部时间工作日早晨4:30到下午1:30',
  cannottransfermorethanyouown: '转账额度不能大于账户金额',
  cannotwithdrawmorethanyouown: '提款额度不能大于账户金额',
  allfieldsarerequired: '所有均为必填项',
  transactionhasbeensuccessfullycompleted: '交易已成功完成',
  wiredepositinstructions: '电汇存款指南',
  wiredepositinstructions1: '请在电汇转账时于memo中注明账号',
  wiredepositinstructions2:
    '注释：美国银行账户没有部分国际电汇业务需要的IBAN号。您需要DriveWealth的收款人账号和SWIFT码办理国际电汇转账业务',
  wiredepositinstructions3:
    'SIPC（美国政府）对此账户的所有资金提供最多50万美元的担保',
  wiredepositinstructions4:
    '银行账户名必须与您的Investall账户名匹配。本操作不接受任何第三方电汇',
  wiredepositinstructions5:
    '收款人姓名必须和您的Investall账户相符。任何第三方电汇都不予以受理。',
  remittanceinformation: '汇款信息',
  usdollaramount: '美元金额',
  recipientbankname: '收款行名称',
  bankaddress: '收款行地址',
  abanumber: 'ABA码',
  swiftcode: 'SWIFT码',
  bankaccountnumber: '银行账号',
  forfinalcreditto: '最终收款账户',
  selectaccounttoview: '选择账户',
  name: '姓名',
  wirewithdrawal: '电汇提款',
  fromaccount: '提款账户',
  beneficiaryname: '收款人姓名',
  fullname: '全名',
  beneficiaryaccountnumber: '收款账号',
  accountnumber: '账号',
  beneficiaryaccounttype: '收款账户类别',
  accounttype: '账户类别',
  bankname: '银行名称',
  beneficiaryswiftaba: '收款账户Swift/ABA码',
  swiftcodeoraba: 'Swift或ABA码',
  beneficiarybankname: '收款银行名称',
  beneficiarybankaddress: '收款银行地址',
  beneficiarybankcity: '收款人银行所在城市',
  bankcity: '银行所在城市',
  beneficiarybankprovince: '收款人银行所在省份',
  bankprovincestate: '银行所在省份或所在州',
  beneficiarybankzipcode: '收款人银行邮编',
  bankzipcode: '银行邮编',
  beneficiarybankcountry: '收款人银行所在国',
  bankcountry: '银行所在国',
  amounttowithdraw: '提款金额',
  notes: '备注',
  enternoteshere: '输入备注',
  withdraw: '提款',

  sell: '卖出',
  stockaccount: '自主交易账户',
  roboaccount: '自动交易账户',
  cryptoaccount: '加密货币账户',
  'ai stock traderaccount': 'AI股票交易系统',
  'ai crypto traderaccount': 'AI加密货币交易系统',
  goalsaccount: '理财服务',
  dollars: '美元',
  estimatedcost: '预估成本价',
  revieworder: '预览委托单',
  ordersuccessful: '委托单交易成功',
  numberofshares: '股数',
  sellall: '全部卖出',
  limitprice: '限价',
  chart: '图表',
  analysis: '分析',
  stats: '统计数据',
  oneday: ' 1天',
  oneweek: '1周',
  onemonth: '1个月',
  open: '开盘价',
  high: '最高价',
  low: '最低价',
  portfoliodetails: '投资组合细节',
  accountvalue: '账户金额',
  products: '产品',
  products2: '产品',
  market: '市价',
  limit: '限价',
  investin: '按······计',
  pleaseenterlimitpricegreaterthan0: '请输入大于0的价格',
  deposit: '存款',
  orderhistory: '交易纪录',
  transferhistory: '转账纪录',
  addbankaccount: '添加银行账户',
  disclaimer: '免责声明',
  disclaimerdescription:
    '所有30天（中期）预测值和趋势（短期，2小时到2天）均由我们的AI预测模型生成。它们不构成任何买卖股票的建议和推荐。其结果不代表实际交易，实际结果可能会与在此展示的理论值存在较大偏差。过去的投资表现并不代表将来的投资表现。',
  currentprice: '现价',
  currenttrend: '现趋势',
  selectyouraccounttodeposit: '选择存款账户',
  goalaccount: '理财服务',
  amountmustbeatleast5dollars: '输入数额不能低于5美元',
  amountmustbeatleast1dollar: '输入数额不能低于1美元',
  pleaseopenanaccounttotrade: '请先开户再进行交易',
  gfv1: '资金尚未完成交割前卖出证券会造成违规操作。当您的账户在12个月内发生超过3次违规操作之后，您的账户将面临90天限制',
  gfv2: '这是您12个月内的第三次违规操作（资金尚未完成交割前卖出证券），您的账户将面临90天限制。',
  thismarketorderwillexecuteaftermarkethours:
    '该市价单将于美国东部时间上午9:30后执行',
  goodfaithviolationalert: '违规操作警告',
  youareallcaughtup: '全部邮件已读',
  transfer: '转账',
  bearish: '看跌',
  bullish: '看涨',
  percentchange: '预测价%',
  riskforecast: '风险预测',
  historicaldrawdown: '历史回撤',
  risk: '风险',
  maxdrawdown: '最大回撤',
  historicalstresstest: '历史压力测试',
  neutral: '中性',
  marketcap: '市值',
  peratio: '市盈率',
  dailyvolume: '日成交量',
  volume10davg: '10日平均成交量',
  dividendamount: '股息额',
  dividendyield: '流通股',
  sharesoutstanding: '流通股',
  shortinterest: '短期利率',

  personalinformation: '个人信息',
  linkedaccounts: '关联账户',
  manage: '管理',
  faqs: '常见问题',
  support: '技术支持',
  legal: '法务',
  email: '邮件',
  phone: '电话',
  accountinformation: '账户信息',
  signout: '注销登录',
  banking: '银行账户',
  investment: '投资账户',
  linkabankaccount: '关联更多银行账户',
  linkainvestmentaccount: '关联更多投资账户',
  subscriptionmanage: '订阅-管理',
  investmentquestionnaireupdate: '投资问卷-更新',
  changepassword: '修改密码',
  oldpassword: '旧密码',
  password: '密码',
  newpassword: '新密码',
  confirmnewpassword: '确认新密码',

  activaterobo: '激活自动交易',
  'activateai stock trader': '激活AI股票交易系统',
  'activateai crypto trader': '激活AI机密货币交易系统',
  continueautomatedtradingby: '通过激活您的账户继续自动交易',
  activate: '激活',
  investallrobo: '自动交易',
  investmentamount: '投资金额',
  periodiccontribution: '分期存入金额',
  strategyusedbyrobo: '自动交易策略',
  roboisnotactiveyet: '自动交易未激活',
  startrobonowtoinvest:
    '启动自动交易以便使用Investall为您量身定制的自动投资策略进行投资',
  monthtodate: '月初至今盈亏',
  yeartodate: '年初至今盈亏',
  expectedpotentialrisk: '预期潜在风险（30天预测）',
  viewallocations: '预览仓位分配',
  gipscompliant: 'GIPS认证',

  investmentcalculator: '投资计算器',
  value: '金额',
  values: '金额',
  in10years: '10年后将增长至',
  growth: '金额',
  aminimumof500isrequired: '使用Investall Robo的最低资金要求为$500。',
  initialinvestment: '起始资金',
  monthlycontribution: '按月分期存入',
  weeklyroundup: '按周',
  addcash: '存入现金',
  additional: '需要再存入',
  isrequiredtomeetyourinitialinvestment: '以满足起始资金要求',

  yoursubscription: '您的订阅',
  status: '状态',
  autorenewson: '订阅自动续期日期',
  downgradetobasic: '降级为基础版',
  boosttopro: '升级为专业版',
  includesallfeaturesofinvestallbasicandmore:
    '包括InvestAll基础版的所有功能以及额外更强大专业的功能！',
  freetrial: '30天试用',
  afterward: '试用期后价格为$3.99',
  start1monthsfreetrial: '开通1个月免费试用',
  cancelanytime: '可随时取消',
  investingmaderight: '正确投资',
  investinoursignaturerobo: '使用我们的旗舰自动交易策略进行投资',
  convenience: '便利性',
  letouraidaytradeforyou: '我们的AI日常交易系统为您提供无与伦比的便捷服务',
  improveyourspending: '优化消费',
  letourbudgetingsoftware: '我们的预算制定软件能帮助您全面优化您的消费',
  peaceofmind: '贴心定制',
  prepareforyourfuture: ' 我们的专业理财服务为您更好地规划未来',
  financialsecurity: '金融安全',
  getthestatstobackup: '我们的风险管控工具为您保驾护航',
  trialmessage: '*订阅后即刻开始免费试用1个月',
  trialmessage2: '*某些产品的最小投资额为$500',

  whatisthemainreasonforyourinvestment: '您投资的主要期限是什么？',
  shortterm: '短期',
  mediumterm: '中期',
  longterm: '长期',
  howdoyouwishtoinvest: '您预期的投资年限是多少？',
  whatisyourrisktolerance: '您的风险耐受力是多少？',
  minimizelosses: '最小化损失',
  gainswhilelimitinglosses: '在控制一定损失的前提下提高收益',
  maximizegains: '最大化收益',
  whatisyourinvestmentexperience: '您有多少投资经验？',
  noexperience: '无经验',
  someexperience: '一些经验',
  considerableexperience: '大量经验',
  whatisyourinvestmentobjective: '您的投资风格是什么？',
  longtermbuyhold: '买入长期持有',
  tradinginfrequently: '不经常交易',
  frequenttrader: '频繁交易',
  activedailytrader: '活跃的日内交易',
  newtoinvesting: '投资新手',
  investmentperiod: '投资期限',
  pleasecompleteallfields: '请填写所有项',
  questionnaireupdatedsuccessfully: '问卷更新成功',
  investmentquestionnaire: '投资问卷',
  cannotactivateaccountduringnontradinghours: '闭市期间无法激活账户',
  pleaseselectoneofthevaluesabove: '请从以上数值中选择',
  donthaveanaccount: '没有账户?',
  pleasedonotleavethispage: '请不要离开此页',
  pleasechooseavaluegreaterthan0orroundupoption:
    '请选择大于1的数值或向上舍入选项',
  roundup: '向上舍入',
  areyousureyouwanttodelete: '请确认取消',
  recurringbuy: '重复买入',

  daily: '每日',
  weekly: '每周',
  biweekly: '每两周',
  monthly: '每月',

  youhavebeensuccessfullyunsubscribedtoinvetallpro:
    '您已成功取消Investall Pro订阅',
  youhavebeensuccessfullysubscribedtoinvestallpro: '您已成功订阅Investal Pro',
  successfullysubscribed: '取消订阅成功',
  thisaccountneedstoberelinked: '请重连此账户',
  areyousureyouwanttoremovethisaccount: '请确认移除此账户',
  areyousureyouwanttoproceed: '请确认继续此操作',
  youhavebeenloggedout: '您已注销。点击此处重新登录',

  pleasechooseabankaccount: '请选择银行账户',
  selectfrequency: '请选择频率',
  changesmadesuccessfully: '更改成功',
  successfullyunsubscribed: '取消订阅成功',
  availabletowithdraw: '可取款金额',

  //LANDING PAGE
  business: '业务',
  signup: '用户注册',
  login: '登录',
  landingpagetitle: '基于人工智能算法的智能投资',
  landingpageinfo: '免手续费买入股票和加密货币市场零股',
  investallforecast: 'INVESTALL预测',
  threemopriceforecast: '3个月价格预测',
  findouttheshortterm: '了解更多您感兴趣的短期和长期预测结果',
  gettheapp: '下载APP',
  features: '我们的特色',
  automatedinvestingmadeeasy: '自动化投资，让一切更简单',
  'Invest Robo': '自动投资',
  'AI Trader': 'AI交易系统',
  Goals: '理财服务',
  GOALS: '理财服务',
  goal: '理财服务',
  Budgeting: '预算规划',
  budgeting: '预算规划',
  learnmore: '了解更多',
  roboinfo: '我们的AI投资引擎为您提供主动性风险管控和严格的投资策略执行',
  aitraderinfo:
    'AI交易系统能有效减少时间投入并提高投资表现。自动化交易系统利用价格趋势预测和AI算法得出的信号为您选择交易执行的最佳时机',
  goalsinfo: '我们为您简化并量身定做未来财务规划',
  budgetinginfo:
    '为您汇集所有财务账户信息。您的关联账户越多，我们的AI便能更好地为您评估风险，消费习惯以及更多您需要的信息',

  home: '主页',
  about: '关于',
  contact: '联系方式',
  careers: '招贤纳才',
  privacypolicy: '隐私条款',
  termsofuse: '服务条款',
  eula: 'EULA',
  generaldisclaimer: '免责声明',
  disclosures: '信息披露',

  createanaccount: '创建账户',
  createaccount: '创建账户',
  phonenumber: '电话号码',
  forgotyourpassword: '忘记密码',
  pleaseagreetoprivacypolicyandeula: '请同意隐私和EULA条款',
  pleaseenterpassword: '请输入密码',
  pleaseentervalidemail: '请输入有效电子邮件地址',
  chooseaverificationmethod: '选择验证方式',
  alreadyreceivedcode: '已收到验证码',
  entercode: '输入验证码',
  verificationcodesentmessage: '验证码已发送。请输入验证码继续',
  didntgetthecode: '未收到验证码?',
  resendcode: '重新发送验证码',
  failedtoresentcode: '验证码重发失败',
  invalidcode: '无效的验证码',
  pleaseenteryouremaildownbelow: '请在下方输入您的电子邮件地址',
  enteryourpasswordbelow: '请在下方输入密码',
  confirm: '确认',
  iagreetothe: '同意',

  invalidphonenumber: '无效的电话号码',
  invalidpassword: '无效的密码',
  codehasbeensent: '验证码已发送',
  getstarted: '开始注册',
  seeproducts: '浏览产品',

  cryptotabtitle: '用AI交易系统自动化加密货币交易.',
  cryptotabinfo:
    ' 探索从比特币到以太坊等世界顶级加密货币。我们的高级人工智能交易工具能有效保护您的投资。下载我们的APP进行加密货币交易。',
  cryptocurrencyforecast: '加密货币预测',

  investing: '投资',
  institutionallevelroboinvesting: '机构级别的自动投资管理',
  producttabinfo:
    '最先进的交易策略，投资业绩经过全球投资业绩标准审核，远优于其他顶级自动交易顾问',
  dynamicassetmanagement: '动态资产管理',
  startrobo: '启用自动交易服务',

  investrobo: '自动投资管理',
  whypickstockswhenyoucanletouraiinvestforyou:
    '有我们的AI智能投资系统，您无需再自己选择股票',
  trustedbythebigbanks: '受各大投行信任青睐',
  bigbanksinfo:
    '投资业绩经过全球投资业绩标准审核，远优于其他顶级自动交易顾问，现向大众提供同样的投资服务',
  tailoredtoyourchoices: '通过您的选择为您量身定做',
  tailoredinfo:
    '完成投资问卷调查，我们的AI智能投资管理会为您的账户挑选最佳投资策略',
  highlyliquid: '高流通性',
  highlyinfo:
    '您可随时改变主意，在您需要的时候随时取出资金。我们的投资策略会根据市场变化每月调仓',

  aitrader: 'AI交易系统',
  aitraderheader: '选择最多四种股票或加密货币，AI交易系统会自动为您交易',
  aitraderinfo1: 'AI交易系统通过监测价格趋势的变化为您提供全天候交易操作',
  aitraderinfo2: '和对冲基金类似的加杠杆类AI自动交易策略',
  aitraderinfo3: '根据多因子模型建立的动量变化监测体系，自动执行交易',
  aitraderinfo4: 'AI交易系统通过优化交易体量更好地降低风险提高收益',

  goals: '理财服务',
  goalsheader: '确定您的理财目标，可视化您的目标途径。做好当下，着眼未来',
  goalsinfo1: '为您未来的房贷规划储蓄',
  goalsinfo2: '为您2.5万的假期消费设定储蓄目标',
  goalsinfo3: '为您的医疗保健设置应急基金',
  goalsinfo4: '亦或是为您的退休养老设置长期储蓄目标',

  budgetingheader: '用中心化的概览页面规划财务',
  budgetinginfo1: '适用于长期储蓄目标——用于退休、大学基金以及大笔购买开支',
  budgetinginfo2:
    '只需为您的投资组合设定储蓄目标和投资策略，ForeSCITE 便可更有效地引导您的投资和消费习惯',
  budgetinginfo3: '设置每月自动定存，帮助您向预定储蓄目标前行',
  freeversion: '免费版本',
  freeversioninfo: '可立即使用我们的自主投资，自动投资系统和价格趋势预测服务',
  upgradetools: '升级版本',
  upgradetoolsinfo: '可升级使用预算规划，理财以及AI自动交易系统等服务',

  yourpasswordhasbeenreset: '您的密码已重设。请用新密码登录',
  incorrectpassword: '密码错误',
  advisoryagreement: '咨询协议',
  enteryournewpasswordbelow: '请在下方输入新密码',
  pleaseenterthecodetoverifythisisyouremail: '请输入代码验证您的电子邮件地址',
  usernamenotfound: '找不到用户名',
  userisnotconfirmed: '用户未确认。请输入确认码或申请新确认码',
  wesentaverificationcodeto: '已发送验证码至',

  back: '上一步',
  technicalanalysis: '技术分析',
  realtiveindexstrength: '相对指数强度',
  expmovingaverage: '指数移动平均',
  day: '日',
  chaikinmoneyflow: '佳庆资金流量指标',
  technicallevels: '技术位',
  resistance: '阻力位',

  moderate: '中度',
  change: '变动',
  roboinvestinginfo1: '我们的AI智能投资系统为您量身定制最适合您的投资策略',
  roboinvestinginfo2: '我们的投资策略根据市场波动定期调仓',
  roboinvestinginfo3: '自动交易系统可设置自动定存，汇集零星资金统理您的投资',
  investingmadeeasy: '简化投资过程，降低零星股买卖手续费到最低1美元',
  investingmadeeasyinfo1: '买卖个股和指数型基金（ETFs)均免手续费',
  investingmadeeasyinfo2: '使用我们的价格趋势和30天价格预测工具为您的投资导航',
  investingmadeeasyinfo3:
    '买卖任何股票（特别是高价股）的零星股，让你的投资更灵活便捷',

  setanalert: '设置提醒',
  set: '设置',
  selectfromindicators: '选择技术指标',
  oneweeksupport: '周支撑位',
  oneweekresistance: '周阻力位',
  onemonthsupport: '月支撑位',
  onemonthresistance: '月阻力位',
  bollingersupport: '布林线支撑位',
  bollingerresistance: '布林线阻力位',
  selectfromcrossovers: '选择交叉指标',
  bullishcrossover: '黄金交叉',
  bearishcrossover: '看跌交叉',
  alerts: '提醒',
  cancelalert: '取消提醒',
  areyousureyouwanttocancelthisalert: '确认删除此提醒?',
  logintoyourinvestallaccount: '登录您的Investall账户',
  enteramount: '输入金额',
  allaccounts: '所有账户',

  temporarypassword: '临时密码',
  clickheretologin: '点此登录',
  passwordupdatedsuccessfully: '已成功更新密码',

  inprogress: '执行中',
  producthasbeensuccessfullyactivated: '产品已成功激活',
  overview: '概述',
  active: '启用',
  startdate: '起始日期',
  contribution: '定期存入金额',
  currentvalue: '现金额',
  rebalanceinformation: '调仓信息',
  nextrebalance: '下一次调仓日',
  lastrebalancedate: '上一次调仓日',
  daysuntilnextrebalance: '距离下一次调仓',
  intramonth: '月内调仓',

  clickheretodownload: '点击此处下载',
  noinvestments: '无投资项目',
  noorderhistory: '无委托历史',
  notransferhistory: '无转账历史',
  notaxdocuments: '无税务文件',
  noaccountstatements: '无账户报表',
  notradeconfirmations: '无交易确认',

  deactivaterobo: '停止自动投资服务',
  robohasbeensuccessfullydeactivated: '自动投资服务已成功停止',
  cannotdeactivateroboduringnontradinghours: '非交易时段无法中止自动投资服务',
  periodiccontributionupdatedsuccessfully: '自动定存设置成功',
  thesparechangemessage1: '此账户每日会自动汇集您消费结余资金加入投资池',
  thesparechangemessage2:
    '该功能将汇集您所有已连银行账户和信用卡账户的零星资金',
  clickhereforfrequentlyaskedquestions: '点击此处查看常见问题',

  pleaseenteryouroldpassword: '请输入旧密码',
  pleaseconfirmyournewpassword: '请确认新密码',
  yournewpasswordmustbeeightormorecharacters: '新密码必须是至少8位字符',
  newpasswordandconfirmdoesnotmatch: '新密码和确认密码不匹配',
  incorrectusernameorpassword: '用户名或密码错误',
  youroldpasswordisincorrect: '您输入的旧密码错误',
  pleaseenteryournewpassword: '请输入新密码',
  passwordmustbegreaterthan8characters: '密码必须大于8位字符',
  userverification: 'KYC验证',
  pleaseclickheretoverifykyc: '点击此处进行KYC验证',
  kycverification: 'KYC验证',
  pleaseremovepreviousimagetouploadanotherone: '请删除旧图上传新图',
  documenthasbeenuploadedforapproval: '文件已上传，等待批准',
  failedtouploaddocument: '上传文件失败',
  pleasewaituntilverificationiscomplete: '请等待验证完成',
  kycapproved: 'KYC验证通过',
  selectdocumenttype: '选择文件类型',
  documenttype: '文件类型',
  uploadfrontside: '上传正面',
  uploadbackside: '上传背面',

  thiswillcreatea: '此操作将为您创建',
  accountforyou: '账户',
  wouldyouliketoproceed: '点击继续?',
  getrobo: '创建自动交易账户',
  createroboaccount: '创建自动交易账户',
  setuprecurringbuy: '设置定期买入',
  editrecurringbuy: '编辑定期买入',
  recurringbuyupdatedsuccessfully: '定期买入设置成功',
  achlinking: 'ACH关联',
  realtimeverification: '即时验证',
  wiredeposit: '电汇存款',
  transferamount: '转账金额',
  createastockaccount: '创建股票账户',

  highrisk: '高风险',
  lowrisk: '低风险',
  moderaterisk: '中风险',
  logintoyour: '登录您的',
  beneficiaryaddress: '收款人地址',
  account: '账户',
  usbankaccountsonly: '仅限美国银行账户',
  recurringbuysetupsuccessfully: '定期买入设置成功',
  orderplacedsuccessfully: '委托单交易成功',
  'ai crypto trader': 'AI加密货币交易系统',
  'ai stock trader': 'AI股票交易系统',
  sessiontimeout: '会话超时。点击此处重新登入',
  return: '收益',
  successfullydeposited: '资金存入成功',

  accountbuyingpower: '账户可用金额',
  addstocks: '添加股票',
  startaitrader: '开启AI交易服务',
  pleaseselectatleast2stocks: '请选择至少两支股票',
  addastocktoaitrader: '添加股票至AI交易系统',
  searchbysymbol: '用代码搜索',
  additionalisrequiredtomeetyourinitialinvestment:
    '达到您的起始投资金额要求还需要',
  deactivateaistocktrader: '中止AI股票交易服务',
  aistocktraderhasbeensuccessfullydeactivated: '已成功中止AI股票交易服务',
  cannotdeactivateaistocktraderduringnontradinghours:
    '无法在非交易时间中止AI股票交易服务',
  removedsuccessfully: '成功移除',
  accountremovedsuccessfully: '账户成功移除',
  addcrypto: '添加加密货币',
  cannotselectmorethan8cryptos: '无法选择多于8种加密货币',
  marketsareonlyopenfrom: '开市时间为美国东部时间早晨9:30到下午4:30',
  pleaseselectatleast2cryptos: '请选择至少2种加密货币',
  editexistingachlinking: '编辑已有ACH连接',
  deleteexistingachlinking: '删除已有ACH连接',
  startaicryptotrader: '启用AI加密货币交易系统',
  aicryptotraderhasbeensuccessfullyactivated: 'AI加密货币交易系统已成功激活',
  productdetails: '产品细节',
  signupsuccessful: '注册成功',
  emailaddress: '电子邮箱地址',
  buystocks: '买入股票',
  signin: '登录',
  cryptoisnotsupportedinyourarea: '加密货币交易不对美国居民以外的用户开放',
  thiscryptoisnotsupporedfornewyorkresidents: '该加密货币不对纽约州居民开放',
  getstock: '股票账户开户',
  getcrypto: '加密货币账户开户',
  createcryptoaccount: '创建加密货币账户',
  unopenedrobodetails:
    '请完成问卷，我们会为您挑选最适合您投资目标和风险等级的投资策略。我们所有的投资策略都具备高流动性并按月调仓',
  unopenedaistockdetails:
    '请选择最多四种股票，AI自动交易系统将根据智能算法检测到的价格动量变化来为您自动交易',
  getaistocktrader: '开通AI股票自动交易服务',
  createaistocktraderaccount: '创建AI股票交易账户',
  unopenedaicryptodetails:
    '请选择最多四种加密货币供我们的AI自动交易系统为您交易',
  getaicryptotrader: '开通AI加密货币自动交易服务',
  unopenedgoalsdetails:
    '投资理财服务将为您设计理财规划并追踪执行动态，将您的资金投入我们表现优异领先行业同行的投资策略，让您的积蓄获得最佳收益，稳步向您的理财目标迈进',
  getgoals: '开通理财服务',
  creategoalsaccount: '创建理财服务账户',
  thiswillcreatecrypto: '您将创建一个加密货币账户，点击继续',
  thiswillcreateaistock: '您将创建一个AI股票交易账户，点击继续',
  thiswillcreateaicrypto:
    '您将创建一个AI加密货币交易账户，点击继续您将创建一个AI加密货币交易账户，点击继续',
  thiswillcreategoals: '您将创建一个理财服务账户，点击继续',
  noallocations: '无仓位分配',
  oldpasswordcannotbethesameasnewpassword: '新密码不能和旧密码相同',
  chooseanoption: '选择期权',
  thisaccountispendingapproval: '该账户处于待批状态',
  available: '可用金额',
  linkaccount: '关联账户',
  accountsuccessfullylinked: '账户已关联成功。两分钟后将被激活',
  doyouwanttolinkthisaccount: '是否需要关联此账户？',
  depositcompletedsuccessfully: '存款成功',
  withdrawcompletedsuccessfully: '取款成功',
  transfercompletedsuccessfully: '转账成功',
  deactivateaicryptotrader: '中止AI加密货币自动交易服务',
  aicryptotraderhasbeensuccessfullydeactivated:
    'AI加密货币自动交易服务已成功中止',
  totalaccountvalue: '账户总金额',
  currently: '目前',
  ontrack: '符合预期',
  offtrack: '偏离预期',
  target: '目标',
  deactivategoals: '中止理财服务',
  goalshasbeensuccessfullydeactivated: '理财服务已成功中止',
  documentupdatesuccessfully: '文件上传成功，将很快为您更新',
  failedtosenddocument: '发送文件失败',
  relinkbankaccount: '重新关联银行账户',
  relinkbrokerageaccount: '重新关联股票经纪账户',
  addbankaccount: '添加银行账户',
  addbrokerageaccount: '添加股票经纪账户',
  forgotpassword: '忘记密码',
  pleaseenterphonenumber: '请输入电话号码',
  toyourinvestallaccount: '登入您的investall账户',
  failedtoresendcode: '重发验证码失败',
  bidaskprice: '买入/卖出 价',
  bidaskexchange: '买卖报价交易所',
  daysrange: '日内幅度',
  time: '时间',
  lasttrade: '最新交易',
  lasttradesize: '最新交易手数',
  lasttradeexchange: '最新交易隶属交易所',
  showdetails: '显示详细信息',
  hidedetails: '隐藏详细信息',
  youraccountisinpendingstatus: '您的账户处于待处理状态',
  liquidatestatusmodemessage:
    '您的账户目前处于仅限清算模式。您只能出售持仓并将资金提取到您的银行账户。',
  youraccountisonliquidateonlymode:
    '您的账户目前只能进行清仓操作。请联系客服进一步处理',

  thefutureofinvesting: '投资的未来',
  automatedprecision: '自动化的精准投资指引',
  aggregateallyouraccountsseeinsifhtsandtrendsandtrackyourexpenses:
    '为您汇集所有账户信息，洞察财务状态和变动趋势，追踪您的消费习惯以及更多您需要的信息',
  cryptotrader: '加密货币自动交易服务',
  exploretheworlds: '探索从比特币到以太币等全球热门加密货币的投资价值',
  seeintothefuture: '为您洞察未来',
  seeintothefutureinfo:
    'Investall利用前沿AI和机器学习算法为您提供不同时间跨度的风险和回报预测及精准的统计数据为您的交易助航',
  pricetrendforecaster: '价格趋势预测',
  tradewithzeroemotion: '理性交易',
  tradewithzeroemotioninfo:
    '我们的自动化交易服务利用强大的人工智能算法满足您的交易和投资需求，使用专业投资策略为您避开市场的崩盘。您可以自选股票或加密货币让AI自动交易系统为您操作，也可直接选用Investall自动交易服务为您提供专业投资策略。此外，我们还提供理财服务为您更快实现财务自由',
  automatedaiinvestor: 'AI自动投资',
  seeinvestallshistoricalmarketforecast: '参见Investall的历史市场预测',
  investallshistoricalmarketforecast: 'Investall的历史市场预测',
  aggregateallyouraccounts: '汇集您的所有账户信息',
  aggregateallyouraccountsinfo:
    '关联您的所有账户综合展示您的财务状况。只需一键点击便能从您的其他股票经纪账户、银行账户以及信用卡账户导入所有信息。我们的预测算法能为您的财产估值，并追踪您所有账户的支出明细并自动汇集于此',
  signintoinvestall: '登录到Investall',
  fullydilutedvaluation: '完全稀释估值',
  circulatingsupply: '流通量',
  totalsupply: '总量',
  nextearningsdate: '下一个财报日',
  nextdividenddate: '下一个股息日',
  earningsexpectedon: '财报：预期为',
  signupsuccessfulyouwillberedirectshortly: '注册成功，将很快刷新',
  'SHORT TERM TARGET': '短期目标',
  'MEDIUM TERM TARGET': '中期目标',
  'LONG TERM TARGET': '长期目标',
  addacryptotoaitrader: '添加加密货币到AI交易系统',
  pleaseselectabankaccount: '请选择银行账户',
  aistocktraderhasbeensuccessfullyactivated: 'AI股票自动交易服务已经成功激活',
  pleaseselectavalidstock: '请选择有效股票代码',
  cannotselectmorethan4stocks: '无法选择多于4种股票',
  yes: '是',
  no: '否',
  goalplanningwilluseyourentireaccountvalue:
    '理财规划服务将使用您账户的所有资金',
  investmentgoal: '投资理财目标',
  yearstoaccumulate: '积攒年限',
  investmentgoalamountmustbegreaterthaninitialinvestment:
    '理财目标必须大于起始投资金额',
  investmentforyourgoal: '理财服务的投资',

  availablebalance: '可用资金',
  rateofreturnofinvestment: '回报率（基于您的理财计划',
  chooseabankaccountforperiodiccontribution: '选择银行账户进行自动定存设置',
  goalpreview: '理财目标预览',
  startgoal: '开始理财服务',
  activategoals: '激活理财服务',
  goalshasbeensuccessfullyactivated: '理财服务已成功激活',
  shorttermgoallabel: '短期理财目标 1到3年',
  shorttermgoalexample: '例如：建立紧急储蓄资金',
  shorttermgoaldescription: '智能目标设定：未来12个月每月积攒$200存款',
  mediumtermgoallabel: '中期理财目标 3到5年',
  mediumtermgoalexample: '例如：买房规划',
  mediumtermgoaldescription: '智能目标设定：未来3年积攒$10000存款作为房贷首付',
  longtermgoallabel: '长期理财目标 5到10年',
  longtermgoalexample: '例如：退休养老储蓄目标',
  longtermgoaldescription: '智能目标设定：未来5年每年积攒$5000存款作为退休基金',
  areyousureyouwanttocancelrecurringbuyof: '是否确认取消定期买入',
  goodfaithviolation: '违规操作',
  pleaseupgradetoinvestallprotoaccessthisproduct:
    '使用该产品请订阅Investall专业版',
  upgradetoinvestallpro: '启用1个月免费试用',
  frequentlyaskedquestions: '常见问题',
  internationalwiredepositinstructions: '国际电汇存款详情',
  passwordmessage:
    '密码必须包含至少8个字符，一个大写字符，一个小写字符，一个数字以及一个特殊字符',
  passwordmust: '密码必须含有',
  beatleast8characters: '至少8个字符',
  haveatleastoneuppercase: '至少一个大写字符',
  haveatleastonelowercase: '至少一个小写字符',
  haveatleastonenumber: '至少一个数字',
  haveatleastonespecialcharacter:
    '至少一个特殊字符 (-!$%^&*()_+|~=`{}[]:/;<>?,.@#\'")',
  pleaseentervalidpassword: '请输入有效密码',
  notavailableinchina: '中国区用户无法下载',
  addtowatchlist: '加入自选股',
  removefromwatchlist: '从自选股中移除',
  tradingandinvesting: '交易与投资',
  investallprosubscription: 'Investall Pro 订阅',
  keystats: '关键数据',
  ratiosprofitability: '盈利率',
  peratiottm: '市盈率（过去一年）',
  netmarginttm: '净利润（过去一年）',
  pricesalesttm: '价格售价比（过去一年）',
  enterprisevaluettm: '企业价值/息税前利润',
  operatingmarginttm: '营业利润率（过去一年）',
  returnonequityttm: '股本回报率（过去一年）',
  events: '事件',
  description: '描述',
  news: '新闻',
  daysrange: '日内幅度',
  lowhigh: '最低价-最高价',
  yearrange: '52周幅度',
  pleaseselectatleastonegroup: '请至少选择一组',
  addedsuccessfully: '添加成功',
  createwatchlist: '创建自选股列表',
  nameofwatchlist: '自选股列表名称',
  searchbysymbol: '按代码搜索',
  entername: '输入名称',
  entersymbol: '输入代码',
  watchlistcreatesuccessfully: '自选股列表创建成功',
  watchlistwiththisnamealreadyexists: '同名的自选股列表已存在',
  pleaseselectatleastonesymbol: '请输入至少一个代码',
  pleaseenternameofwatchlist: '请输入此自选股列表的名称',
  settledcash: '结算现金',
  cashavailableforwithdraw: '可提取现金',
  createnewwatchlist: '创建自选股列表',
  add: '加入',
  towatchlist: '到自选股列表中',
  your: '您的',
  accounthasbeenunlinked: '帐户已取消关联',
  relinktocontinue: '重新链接以继续',
  alreadyhaveanaccount: '已经存在账户? ',
  continuewithmyasaaccount: '继续我的 ASA 账户',
  or: '或',
  someofyouraccountshavebeenunlinked: '您的某些帐户已取消关联。',
  oneofyouraccounthasbeenunlinked: '您的一个帐户已取消关联。',
  pleaseenteramountgreaterthan1: '请输入大于1的数额',
  pleaseentergreatersharesamount: '请输入更大数额的股数',
  cryptomaintenance:
    '由于例行维护，您无法在美国东部时间下午5:30到6:05之间交易加密货币',
  unsettledcashavailableby: '未结算现金在此日期之后可用：',
  downloadapkmessage: '中国区安卓用户请下载此APK',
  rsi: 'RSI指标',
  technicalanalysis: '技术分析',
  technicallevels: '技术水平',
  standard: '标准',
  fibonacci: '斐波那契',

  getexpertstrategies: '获取专业投资策略',
  meetallgoals: '实现所有理财目标',
  automateyourtrades: '自动化您的交易',
  substitute: '替代',
  suggestedcomparables: '推荐替代股',
  stockreplacedsuccessfully: '股票已成功替换',

  //BUDGETING
  activity: '动态',
  view_trends: '查看趋势',
  insights: '要点',
  monthly_average_expenditure: '月均支出',
  income: '收入',
  expenses: '支出',
  ne_expense: '非必要支出',
  emergency_funds: '应急基金',
  available_funds: '可用金额',
  based_on_an_emergency_fund_of: '基于应急基金可用金额',
  one_week: '一周',
  one_month: '一个月',
  three_month: '三个月',
  six_month: '六个月',
  total_income: '总收入',
  total_expense: '总支出',
  total: '总额',
  income_n_expense: '收入和支出',
  bank_fees: '银行费用',
  entertainment: '娱乐',
  food_and_drink: '饮食',
  general_merchandise: '日用品',
  general_services: '一般服务',
  government_and_non_profit: '政府和非盈利机构',
  home_improvement: '家居装修',
  loan_payments: '贷款还款',
  medical: '医药',
  personal_care: '个人护理',
  rent_and_utilities: '租金和水电',
  transportation: '交通',
  travel: '旅行',
  transfer_in: '转入',
  transfer_out: '转出',
  you_are_spending_more_this_month_than_you_do_on_average:
    '您本月支出超出平均值',
  you_are_spending_less_this_month_than_you_do_on_average:
    '您本月支出少于平均值',
  essential: '必要',
  non_essential: '非必要',
  get_your_investall_credit_score: '获取您的Investall信用分数',
  add_credit_score: '添加信用分数',
  credit_score_disclaimer: '信用分数免责声明',
  info_credit_score:
    '信用积分通过您所有关联账户的信息计算得出，在300到850之间。Investall信用积分和其他信用积分的区别在于它通过前沿AI算法综合您的所有个人信息而得出。',
  add_an_account: '添加账户',
  link_bank_account: '关联银行账户',
  link_brokerage_account: '关联股票账户',
  link_asset_or_property: '关联资产或房产',
  add_an_asset: '添加资产',
  add_a_property: '添加房产',
  add_a_vehicle: '添加车辆',
  add_other_valuable: '添加其他有价品',
  find_your_homes_estimated_value: '查询您的房产预估价格',
  enter_full_address: '输入完整地址',
  enter_home_estimated_value: '输入房产预估价格',
  enter_home_estimate: '输入房产预估',
  enter_your_mortgage_balance: '输入您的房贷余额',
  enter_mortgage_balance: '输入房贷余额',
  give_your_property_a_nickname: '给您的房产取个别称',
  enter_nickname: ' 输入房产别称',
  add_property: '添加房产',
  enter_your_vehicle_details: '输入您的车辆详细信息',
  vehicle_model: '车辆型号',
  enter_vehicle_model: '输入车辆型号',
  vehicle_make: '车辆品牌',
  enter_vehicle_make: '输入车辆品牌',
  vehicle_year: '车辆生产年份',
  enter_vehicle_year: '输入车辆生产年份',
  estimated_value: '预估价格',
  enter_vehicle_estimate: '输入车辆预估价格',
  loan_balance: '贷款余额',
  enter_vehicle_loan: '输入车贷余额',
  vehicle_nickname: '车辆别称',
  enter_vehicle_nickname: '输入车辆别称',
  add_vehicle: '添加车辆',
  enter_your_valuable_details: '输入有价品详细信息',
  name: '名称',
  ex_art_jewelery: '例如：艺术品，珠宝项链等',
  add_valuable: '添加有价品',
  net_worth: '净资产',
  physical_assets: '实体资产',
  liabilities: '债务',
  credit_cards: '信用',
  loans: '贷款',
  all_budgeting_notifications: '所有预算提醒',
  based_on_investall_credit_rating: '基于Investall信用评级',
  credit_analysis: '信用分析',
  credit_details_message:
    '您的信用评分体现了您的财务健康状况，您信用表现的相关统计数据如下',
  credit_sore_added_successfully: '信用积分添加成功',
  select_the_highest_education_degree_you_have: '选择您的最高学历',
  select_education: '选择教育情况',
  high_school: '高中',
  bachelor: '本科',
  master: '硕士',
  phd: '博士',
  others: '其他',
  when_was_your_first_line_of_credit_opened:
    '您的第一张信用卡是什么时候开通的？',
  when_did_you_start_your_current_job: '您什么时候开始工作的？',
  start_year: '起始年份',
  what_is_the_first_year_of_your_first_employment:
    '您第一份工作的第一年是哪年？',
  employment_year: '工作年份',
  select_the_option_that_applies_to_you: '选择符合您情况的选项',
  option: '选项',
  renting_a_home: '租房',
  own_a_mortgaged: '有房贷',
  own_a_home: '有房产',
  next: '下一步',
  account_number: '账号',
  balance: '余额',
  address: '地址',
  mortgage_balance: '房贷余额',
  external_accounts: '外部账户',
  depository: '储蓄账户',
  brokerages: '投资账户',
  properties: '房产',
  vehicles: '车辆',
  valuables: '有价品',
  transactions: '交易明细',
  credit: '信用评分',
  add_account: '添加账户',

  //NEW BUDGETING
  no_transactions: '无交易记录',
  please_subscribe_to_access_this_feature: '请订阅以使用此服务',
  link_digital_wallet: '关联数字钱包',
  enter_wallet_address: '输入数字钱包地址',
  add_wallet: '添加数字钱包',
  successfully_added_account: '成功添加账户',
  edit_asset: '修改资产',
  six_mo_expenses: '(6个月支出)',
  you_have_recommended_cash_reserve_for_emergency:
    '您有足够现金储备支持应急基金',
  you_are_below_recommended_cash_reserved_for_emergencies:
    '您的现金已低于维持应急资金需要的现金存量',
  remove_account: '移除账户',
  account_removed_successfully: '账户移除成功',
  remove_account_msg: '本操作将移除和该账户相关的所有子账户，是否移除此账户？',
  remove_asset_msg: '本操作将移除和该资产相关的所有相关信息，是否移除该资产？',
  last_month_expenditure: '上月支出',
  fund_account: '资金账户',
  investall_pro: 'Investall专业版',
  sub_message: '升级使用高阶工具和功能，启用30天试用，30天后每月收费$3.99',
  budgeting_subs_info:
    '汇集您所有账户信息，实时显示您的净资产，为您的房产估价，等等。',
  ai_trader_stock_n_crypto: 'AI股票&加密货币交易',
  ai_trader_stock_n_crypto_sub_info:
    '选择最多4种股票或加密货币，利用高阶算法辅以机器学习预测功能进行自动交易',
  goals_sub_info:
    '跟踪您的支出，找出能为您省钱的款项。我们的趋势和深入分析服务为您提供生动的图表和警示，让您能时刻掌握您的现金流动态。',
  free_trial_msg: '订阅后将立刻开启一个月免费试用。之后将收取每月$3.99的服务费',
  congratulations: '祝贺您',
  activity_trends: '动态和趋势',
  activity_trends_info:
    '跟踪您的支出，找出能为您省钱的款项。我们的趋势和深入分析服务为您提供生动的图表和警示，让您能时刻掌握您的现金流动态。',
  manage_subscription: '管理订阅',
  you_have_successfully_unsubscribed: '您已成功取消订阅',
  successfully_unsubscribed: '取消订阅成功',
  self_trading_accounts: '自主交易账户',
  automated_products: '自动化产品',
  explore: '探索',
  average: '平均值',
  below_averge: '低于平均',
  excellent: '出色',
  remove_asset: '移除资产',
  successfully_added_wallet: '成功添加数字钱包',
  digital_wallets: '数字钱包',
  this_month: '本月',
  good: '良好',
  you_have_spent_less_last_month: '您上月开销低于您过去半年的平均开销',
  'Net Worth': '净资产',
  'Liquidity Ratio': '流动资产比率',
  'Solvency Ratio': '偿付比率',
  'Debt / Income Ratio': '负债收入比率',
  'Debt / Asset Ratio': '资产负债率',
  'Non-Essential Expense Ratio': '非必要费用比率',
  'Essential Expense Ratio': '必要费用比率',
  'Emergency Reserve Required': '所需应急储备',
  'Saving Ratio': '储蓄率',
  'Mortgage Ratio': '按揭成数',
  'Life Insurance Ratio': '人寿保险比率',
  'Retirement Savings Ratio': '退休储蓄比率',
  'Investing Ratio': '投资比率',
  download_app_text: '请在下方下载Investall app。国际用户也可直接下载APK',
  you_have_spent_more_last_month: '您上月开支高于过去半年的平均开支',
  you_have_spent_the_same_amount: '您上月开支与过去半年的平均开支持平',
  this_wallet_does_not_own_any_nfts: '该数字钱包没有任何NFT',
  powered_by_honely: '由Honely提供',
  get_personalized_dashboard_with_your_full_financial_picture:
    '个性化概览页面为您整合全方位财务状况',
  connect_your_accounts_to_investall_msg:
    '连接您的账户到Investall能让您在直观的界面中查看您投资组合的完整概览，从而做出更好的决策。请在通知栏查看重要事件以及自动交易工具。',
  get_insights_on_all_your_accounts: '深入了解您所有账户的状态',
  our_ai_aggregates_message:
    '我们的AI算法能根据您所有资产和负债情况迅速整合出净资产现值。AI亦能轻松关联您的房产并给出其现值',
  my_assets_and_income_managed_regularly: '定期管理资产和收入',
  check_the_current_msg:
    '使用我们的嵌入式预测AI算法为您的房产和其他资产估值，洞察您的的收入活动及趋势',
  know_your_net_worth: '了解您的净资产',
  our_ai_aggregates_the_current_value_msg:
    '我们的AI算法能根据您所有资产和负债情况迅速整合出净资产现值。智能提醒和活动面板的应用让一切变得简单。AI亦能轻松关联您的房产并给出其现值',
  buy_fractional_shares_msg: '购买零碎股免佣金，购买值可低至1美元',
  automate_trading_with: 'AI自动交易和理财服务让交易自动化',
  upgraded_tools: '升级工具',
  trades_are_automatically_msg:
    '交易下单基于动量变化自动进行，动量变化结果源自数十万因子的组合。我们的AI通过优化交易下单从而更好地降低风险并收割获利',
  automate_your_trading_msg: '选取最多四种股票或加密货币进行自动交易',
  its_important_to_set_goals_msg:
    '为您财务的未来设定目标十分重要。无论是您的下一次房贷按揭还是家庭度假，理财服务都能为您自动化全流程，而您只需放松并设想您的未来。根据您对理财周期的要求选择策略，理财服务通过提供一定程度的可预测性，将您的愿望变为现实',
  free: '免费',
  get_robo_for_automated_trading_msg:
    '启用自动交易服务，获取我们所有的预测数据和预测筛选功能，使用股票和加密货币自主交易，交易免佣金，成交值可低至1美元',
  download: '下载',
  forecasting: '预测',
  forecast_screener: '预测筛选',
  fractional_trading: '零碎股交易',
  all_free_features: '所有免费服务',
  upgrade: '升级',
  per_month: '/月',
  everything_from_free_version_msg:
    '包括免费版本的一切服务外加AI股票和加密货币交易，理财服务，以及综合预算概览等升级服务',
  initial_amount: '起始金额',
  contribution_amount: '定投金额',
  investment_period_30_years: '投资期限：5年',
  trusted_by_institutional_traders: '为机构交易员所信赖',
  audited_track_record_msg:
    '投资业绩经过全球投资业绩标准审核，记录了可观的风险调整回报率',
  other_top_robo_advisors_msg:
    '远优于其他顶级自动交易顾问，现向大众提供同样的投资服务',
  tailored_to_your_choices: '根据您的选择为您量身定制',
  complete_an_investment_msg:
    '完成投资问卷调查，我们的AI智能投资管理会为您的账户挑选最佳投资策略',
  highly_liquid: '高流通性',
  keep_full_custody_msg:
    '完全自主保有您的投资账户，在改变主意的时候也能轻松转出您的资金',
  investall_robo: 'Investall自动交易服务',
  financial: '财务',
  revenue: '收入',
  eps: '每股收益',
  ebitda: '利息、税项、折旧及摊销前盈利',
  quarter: '季度',
  financial_forecast: '财务预测',
  price_forecast: '价格预测',
  promo_applied_successfully: '优惠码使用成功',
  user_is_an_already_existing_user: '该用户不是新用户或者已经使用过优惠码了',
  invalid_promo_code: '优惠码无效',
  enter_promo_code: '请输入优惠码',
  please_enter_promo_code: '请输入优惠码',
  my_code: '我的优惠码',
  promo_code: '优惠码',
  rewards: '奖励',
  promotions: '优惠',
  no_rewards_available: '无可用奖励',
  you_have_been_subscribed_to_a_new_reward: '您已订阅新奖励',
  eligibility: '获奖资格',
  eligibility_not_met: '不合格',
  success: '成功',
  requirements_not_met: '未满足要求',
  reward_amount: '奖励金额',
  must_be_a_new_user: '必须为新用户',
  deposit_minimum_100_dollars: '存入至少100美元',
  welcome_to_investall: '欢迎来到Investall',
  get_20_free_with_and_initial_deposit_of_100:
    '初始预存100美元可领取20美元奖励',
  download_the_app: '下载App',
  open_an_account: '开户',
  make_an_minimum_initial_deposit_of_100: '初始存入资金至少100美元',
  use_faares_code: '以下优惠码自动生效：FAARES',
  faares_has_invited_you_to: 'FAARES邀请您到',
  why_investall: '选择Investall的理由',
  trade_stocks_etfs_and_crypto_msg:
    '交易股票、指数基金和加密货币免手续费，交易金额可低至1美元',
  use_expert_strategyies_msg:
    '使用专业策略进行自动交易。注册即可使用Investall自动交易服务，亦可升级使用AI交易和理财等高级服务',
  accessible_on_multi_platform_msg:
    '可在IOS，安卓，APK和网页APP等多平台上进行操作',
  complimentary_upgrade_to: '免费升级为',
  receive_one_month_subscription_msg:
    '从注册日起即可享用1个月的Investall专业版订阅服务',
  forecast_achieved: '达成预测目标',
  forecasted_date: '预测日期',
  forecasted_price: '预测价',
  quarterly: '每季度',
  yearly: '每年',
  extended_hours_trading: '延长交易服务',
  extended_trading_description:
    '延长交易服务包括两部分：盘前时段（美东时间早晨4点到9点半）和盘后时段（美东时间下午4点到8点）',
  enable_extended_trading_description:
    '您需要启用延长交易时段功能吗？4:15 延长交易时段分为两部分：盘前时段：美东时 4:00AM-9:30AM 盘后时段：美东时间4:00PM-8:00PM',
  disable_extended_trading_description:
    '您需要停用延长交易时段功能吗？延长交易时段分为两部分：盘前时段：美东时间4:00AM-9:30AM 盘后时段：美东时间4:00PM-8:00PM',
  i_agree_to_the: '我同意',
  drivewealth_extended_hours_trading_disclosures: '嘉维的延长交易服务披露声明',
  enable: '启用',
  disable_for_now: '停用',
  extended_hours_trading_for_stock: '股票账户延长交易服务',
  extended_hours_popup_prompt: '盘前和盘后交易服务已可用！一键点击下方便可开通',
  use_extended_hours: '使用延长交易时段',
  limit_orders_are_good_until: '限价单在延长交易时段结束前均有效',
  subscription_disclaimer:
    '请仔细阅读本披露。通过下载或使用我们的应用程序，您同意受到下面规定的条款和条件的约束。我们的应用程序的免费版本仅供美国居民使用。如果您是国际用户，并且账户余额超过50美元，则将向您收取3.99美元的平台费。这项费用将自动从您的账户余额中扣除。我们保留在任何时候修改本披露的权利，并无需事先通知。这些修改将在应用程序中发布后立即生效。您同意定期查看本披露，以了解任何此类修改。您继续使用我们的应用程序即视为您接受修改后的披露。如果您对本披露有任何疑问，请联系我们。',
  forecastmargin: '预测偏差',
  prediction: '预测值',
  actual: '实际值',

  // My Finances
  my_finances: '我的财务状况',
  financial_yoga: '财务瑜伽',
  financial_yoga_summary:
    '加强您的财务思维并训练我们的人工智能以更好地为您服务。',
  you_saved: '您省下了',
  dynamic_spending: '动态支出',
  dynamic_spending_explanation:
    '将消费决策框定为两个您想要的类别之间的权衡，而不是专注于“坚持预算”。',
  view_insights: '查看分析',
  available_to_spend: '可用支出',
  income_reserves: '收入储备',
  available_reserves: '可用储备',
  income_reserves_explanation:
    '根据{{fund}}的紧急基金（{{months}}个月的开支）。您的紧急现金储备低于推荐额度。使用我们的预算工具来帮助您增加储蓄。',
  wealth_fund: '财富基金',
  available_to_invest: '可投资金额',
  charitable_giving: '慈善捐赠',
  available_to_give: '可捐赠金额',
  wealth_fund_subtext: '使用可用资金来开始积累财富并偿还高利息债务。',
  contribution_goal: '贡献目标',
  last_month: '上个月',
  six_month_avg: '6个月平均',
  charitable_giving_subtext:
    '这是您为慈善捐赠分配的资金。您可以设定每月收入中想要分配的金额',
  income_reserves_subtext:
    '这包括了您所有关联账户中的可用现金，减去任何当前分配到动态支出类别中的金额。',
  allocation_goal: '分配目标',
  monthly_highlights: '每月要点',
  cash_flow_for_s: '{{date}}的现金流',
  previous_highlights: '过往要点',
  spend_goal: '消费目标',
  hi_s_lets_begin: '你好{{name}}。让我们从审查您的收入和支出开始。',
  mtd_monthly_average: '本月迄今/月平均',
  this_looks_correct: '这看起来是正确的',
  something_is_not_right: '有些不对劲',
  oppurtunity_cost_subtext:
    '训练您的大脑思考“机会成本”自然会让您更聪明地消费，而且它会带来更好的生活结果。',
  s_recap: '{{date}} 回顾',
  connected_accounts: '关联账户',
  opportunity_cost: '机会成本',
  ok_let_s_figure_out_why_things_are_off: '好的，让我们找出问题所在。',
  you_may_be_missing_income_and_expense_transactions:
    '您可能遗漏了收入和支出流水记录。',
  link_additional_accounts: '链接额外账户',
  some_transactions_may_be_going_to_the_wrong_category:
    '一些交易可能被归入了错误的类别。',
  review_transactions: '审查交易',
  transaction_review: '交易审查',
  were_these_ten_transactions_categorized_correctly: '这十笔交易分类正确吗？',
  review: '复查',
  all_good: '一切就绪',
  linked_accounts_subtext: '我们已成功关联以下账户。',
  link_more_accounts: '关联更多账户',
  select_category: '选择类别',
  skip_all_transactions: '跳过所有交易',
  previous: '以前的',
  finish: '完毕',
  steal_from: '偷取',
  oppurtunity_cost_complete_subtext1:
    '您正在加强您的财务思维并帮助我们的人工智能更好地理解您的偏好，以便在未来更好地为您服务。',
  review_opportunity_cost_1:
    '让我们回顾一下你最近的机会成本。如果类别有误，你可以编辑。',
  review_opportunity_cost_2: '你的机会成本是你为完成交易而从中挪用资金的类别。',
  complete: '完成',
  change_category: '更改类别',
  current_category: '当前类别',
  new_category: '新类别',
  transaction_correction_subtext:
    '好的，不用担心。有时候，没有您的帮助，转账可能难以识别',
  transaction_correction_subtext_2: '您可以通过点击下拉菜单选择正确的类别。',
  category_change_suggestions_subtext: '干得好',
  category_change_suggestions_subtext_2:
    '我们已经从您过去的交易中识别出了一些类似的交易。',
  change_selected_to_s: '将选中的更改为 {{category}}',
  search_in_transaction: '在交易中搜索',
  mtd_spend: '本月迄今支出',
  last_month_spend: '上月支出',
  six_month_avg_spend: '6个月平均支出',
  take_from: '取自',
  taken_by_other_categories: '被其他类别占用',
  categories: '类别',
  amounts: '金额',
  dates: '日期',
  update: '更新',
  additionalinformationrequired: '需要额外信息',
  uploadadditionaldocumentsmsg: '请上传其他文件以验证您的身份或地址证明。',
  uploaddocument: '上传文件',
  documentsuploaded: '文件已上传',
  documentsuploadedmsg: '您的文件已成功上传。您的账户状态将很快更新。',
  gotodashboard: '前往仪表板',
  cannotmigrateaccount:
    '您无法将您的Investall账户迁移到Alpaca。请出售并清算您所有的头寸',
  alpacamigrationtitle: '我们升级了我们的经纪服务！',
  instructionstocontinuetrading: '要继续交易，请完成以下步骤：',
  createnewalpacaaccountdetails:
    '创建您的新 Alpaca 账户：点击底部的“开始”按钮来设置您的 Alpaca 账户。',
  assettransferinstructions:
    '资产转移：账户创建后，我们将帮助您将资产转移到 Alpaca。',
  importantnoteaboutaccountstatus:
    '注意：在迁移完成之前，您的当前账户处于“仅清算”模式。',
  alpacaaccountmigration: 'Alpaca账户迁移',
  okay: '好的',
  continue: '继续',
  accountsetupincomplete:
    '您似乎已开始创建 Alpaca 账户但未完成流程。请继续设置您的账户以确保平稳过渡。点击继续以完成账户创建。',
  accountnoteligibleformigration:
    '您的账户不符合迁移到 Alpaca 经纪公司的条件。因此，您的账户已被设置为仅清算状态。这意味着您可以关闭任何未平仓头寸并将资金提取到您的银行账户。如果您遇到任何问题，请随时通过以下方式联系我们：',
  whatsyourname: '你叫什么名字？',
  firstname: '名字',
  lastname: '姓氏',
  whatisyourdateofbirth: '您的出生日期是什么？',
  whatisyourphonenumber: '您的电话号码是什么？',
  areyouaustaxpayer: '您是美国纳税人吗？',
  createinvestingaccount: '创建投资账户',
  signout: '退出',
  personal: '个人',
  employment: '就业',
  financial: '财务',
  investment: '投资',
  acknowledgment: '确认',
  review: '审查',
  accountpendingapproval: '账户待批准',
  accountcreationpending:
    '您的账户创建请求已提交，目前正在等待处理。请允许最多48小时进行批准。',
  gotodashboard: '前往仪表板',
  next: '下一步',
  reviewandcomplete: '审查并完成',
  processing: '处理中',
  acceptandcontinue: '接受并继续',
  contactaddress: '联系地址',
  address1: '地址 1',
  aptsuitebldg: '公寓，套房，建筑编号',
  city: '城市',
  citytown: '城市 / 镇',
  stateprovinceregion: '州 / 省 / 地区',
  zipcode: '邮政编码',
  zippostalcode: '邮编 / 邮政编码',
  citizenship: '国籍',
  countryofbirth: '出生国家',
  confirmresidencestatus: '确认您的居住身份',
  socialsecuritynumber: '社会安全号码',
  taxidentificationnumber: '税号',
  maritalstatus: '婚姻状况',
  numberofdependents: '抚养人数',
  greencard: '绿卡',
  visa: '签证',
  visatype: '签证类型',
  selectvisatype: '选择签证类型',
  visaexpirationdate: '签证到期日期',
  dateofdeparturefromusa: '离开美国的日期',
  taxidentification: '税务识别',
  selectformoftaxidentification: '选择税务识别形式',
  country: '国家',
  employmentinformation: '就业信息',
  employmentstatus: '就业状态',
  unemployed: '失业',
  employed: '在职',
  student: '学生',
  retired: '退休',
  employername: '雇主名称',
  employmentposition: '职位',
  employeraddress: '雇主地址',
  state: '州',
  incomeinformation: '收入信息',
  annualnetincome: '年净收入',
  networth: '净资产',
  liquidnetworth: '流动净资产',
  describeyourliquidityneeds: '描述您的流动性需求',
  primarysourceoffundsforinvesting: '投资的主要资金来源是什么？',
  source: '来源',
  liquidityneeds: '流动性需求',
  veryimportant: '非常重要',
  important: '重要',
  somewhatimportant: '有点重要',
  doesnotmatter: '无所谓',
  employmentincome: '就业收入',
  investments: '投资',
  inheritance: '继承',
  businessincome: '商业收入',
  savings: '储蓄',
  family: '家庭',
  investmentexperience: '投资经验',
  howlongdoyouplantoinvest: '您计划投资多久？',
  investmentplan: '投资计划',
  lessthan1year: '少于1年',
  onetotwoyears: '1到2年',
  threetofiveyears: '3到5年',
  sixtotenyears: '6到10年',
  morethan10years: '超过10年',
  risktolerance: '风险承受能力',
  conservative: '保守型',
  moderate: '适中型',
  significantrisk: '高风险',
  investmenttrading: '投资交易',
  stock: '股票',
  knowledgelevel: '知识水平',
  none: '无',
  limited: '有限',
  intermediate: '中级',
  sophisticated: '高级',
  options: '期权',
  investmentobjective: '投资目标',
  whatisyourinvestmentobjective: '您的投资目标是什么？',
  generateincome: '产生收入',
  partiallyfundretirement: '部分资助退休',
  whollyfundretirement: '完全资助退休',
  steadilyaccumulatewealth: '稳步积累财富',
  preservewealth: '保值',
  payforlargepurchase: '支付大额支出',
  marketspeculation: '市场投机',
  notemployeeofpubliclytradedcompany: '我不是上市公司的员工。',
  dontcontrolpubliclytradedcompany: '我不控制任何上市公司。',
  nofamilymemberorregisteredbroker:
    '没有居住在同一家庭的直系亲属或我本人是注册经纪商，或是证券或商品经纪公司员工、董事或所有者。',
  nosomethingswrong: '不，有些不对劲',
  affiliationsdisclosure: '关联披露',
  includedetaileddescriptionofaffiliation:
    '请详细描述您与上市公司或经纪商的关系。',
  eligibilityconfirmation: '资格确认',
  aboutyou: '关于您',
  fulllegalname: '法定全名',
  single: '单身',
  married: '已婚',
  widowed: '鳏寡',
  divorced: '离婚',
  financialprofile: '财务概况',
  fundingsource: '资金来源',
  incomerange: '收入范围',
  networthrange: '净资产范围',
  liquidnetworthrange: '流动净资产范围',
  investingtimeline: '投资时间线',
  dateofbirth: '出生日期',
  homeaddress: '家庭住址',
  certifynotusperson:
    '我声明我不是美国公民、美国居民外侨或其他美国税务居民，并提交适用的 W-8 BEN 表格以证明我的外国身份以及（如适用）申请税收协定优惠。',
  declareunderpenaltiesofperjury:
    '根据伪证罪，我声明我已检查此表格上的信息，并且根据我的知识和信念，它是真实、正确和完整的。我进一步根据伪证罪认证：',
  individualisbeneficialowner:
    '我是本表格所涉及的所有收入或收益的受益所有人（或被授权为受益所有人签署），或我正在使用此表格为第 4 章目的记录我自己；',
  notusperson: '本表格第 1 行中指定的人不是美国人；',
  formrelatesto: '本表格涉及：',
  incomenoteffectivelyconnected:
    '与在美国从事贸易或商业活动无实质性联系的收入；',
  incomeeffectivelyconnected:
    '与在美国从事贸易或商业活动实质性相关但根据适用的所得税协定不需纳税的收入；',
  partnershipsharetaxableincome:
    '合伙人分享的合伙企业有效相关的应纳税所得额；或',
  partnershiptransferrealizedamount:
    '合伙人因转让合伙权益而实现的金额，需按第 1446(f) 节扣缴。',
  treatycountryresident:
    '本表格第 1 行中指定的人是本表格第 9 行列出的条约国家（如有）居民，并符合美国与该国之间所得税条约的规定；',
  brokertransactions:
    '对于经纪交易或易货交易，受益所有人为符合指示中定义的免税外国人。',
  authorizeformtobeprovided:
    '此外，我授权将此表格提供给任何控制、接收或持有我为受益所有人之收入的扣缴代理人或任何可分发或支付我为受益所有人之收入的扣缴代理人。如本表格中的任何认证变得不正确，我同意在 30 天内提交新表格。',
  agreements: '协议',
  readandagreetoterms:
    '我已阅读、理解并同意遵守 Alpaca Securities LLC 和 Investall 帐户条款，以及适用于我的所有其他条款、披露和免责声明，参考 Alpaca 客户协议第 43 节包含的争议前仲裁条款。',
  understandelectronicagreement:
    '我理解我正在以电子方式签署此协议，并且我的电子签名将具有与亲自签署并返回申请协议相同的效力。',
  accountant: '会计师 / 注册会计师 / 簿记员 / 财务主管',
  actuary: '精算师',
  adjuster: '理赔员',
  administrator: '管理员',
  advertiser: '广告商 / 市场营销 / 公关专业人士',
  agent: '代理人',
  atc: '空中交通管制员',
  ambassador: '大使 / 领事馆专业人员',
  analyst: '分析师',
  appraiser: '评估师',
  architect: '建筑师 / 设计师',
  artist: '艺术家 / 表演者 / 演员 / 舞蹈家',
  assistant: '助理',
  athlete: '运动员',
  attendant: '服务员',
  attorney: '律师 / 法官 / 法律专业人士',
  auctioneer: '拍卖师',
  auditor: '审计员',
  barber: '理发师 / 美容师 / 发型师',
  broker: '经纪人',
  business_exec: '企业高管（副总裁、总监等）',
  business_owner: '企业主',
  caregiver: '护理员',
  carpenter: '木匠 / 建筑工人',
  cashier: '收银员',
  chef: '厨师',
  chiropractor: '脊椎按摩师',
  civil: '公务员',
  clergy: '神职人员',
  clerk: '职员',
  compliance: '合规 / 监管专业人士',
  consultant: '顾问',
  contractor: '承包商',
  counselor: '顾问 / 治疗师',
  customer_service: '客户服务代表',
  dealer: '经销商',
  developer: '开发人员',
  distributor: '分销商',
  doctor: '医生 / 牙医 / 兽医 / 外科医生',
  driver: '司机',
  engineer: '工程师',
  examiner: '检查员',
  exterminator: '灭虫员',
  factory: '工厂 / 仓库工人',
  farmer: '农民 / 牧场主',
  financial: '财务规划师',
  fisherman: '渔民',
  flight: '空乘人员',
  hr: '人力资源专业人士',
  impex: '进口商 / 出口商',
  inspector: '检查员 / 调查员',
  intern: '实习生',
  investment: '投资顾问 / 投资经理',
  investor: '投资者',
  it: 'IT 专业人士 / IT 助理',
  janitor: '清洁工',
  jeweler: '珠宝商',
  laborer: '工人',
  landscaper: '园艺师',
  lending: '贷款专业人士',
  manager: '经理',
  mechanic: '机械师',
  military: '军人、军官或相关人员',
  mortician: '殡仪员 / 殡仪馆主任',
  nurse: '护士',
  nutritionist: '营养师',
  office: '办公室助理',
  pharmacist: '药剂师',
  physical: '物理治疗师',
  pilot: '飞行员',
  police: '警察 / 消防员 / 执法人员',
  politician: '政治家',
  pm: '项目经理',
  rep: '注册代表',
  researcher: '研究员',
  sailor: '水手 / 船员',
  sales: '销售员',
  scientist: '科学家',
  seamstress: '裁缝 / 成衣工',
  security: '保安',
  social: '社会工作者',
  teacher: '教师 / 教授',
  technician: '技术员',
  teller: '出纳员',
  tradesperson: '手工业者 / 技术工人',
  trainer: '培训师 / 指导员',
  transporter: '运输工',
  underwriter: '承销商',
  writer: '作家 / 记者 / 编辑',
  account_pending_approval: '账户待批准',
  account_creation_pending:
    '您的账户创建请求已提交，目前正在等待处理。请允许最多48小时进行批准。',
  go_to_dashboard: '前往仪表板',
  investall_service_termination:
    '由于关系终止，Investall 无法再为您的 DriveWealth 账户提供服务。您可以在此查看并清算您的 DriveWealth 账户。',
  liquidate_accounts: '清算账户',
  national_id: '国家身份证号码，如果没有税号',
  passport: '护照号码，如果没有税号',
  permanent_resident: '永久居民号码，如果没有税号',
  driver_license: '驾驶执照号码，如果没有税号',
  other_gov_id: '其他政府颁发的标识符，如果没有税号',
  not_specified: '其他税号',
  step: '步骤',
  incompleted: '未完成',
  please_make_sure_required_fields_filled: '请确保填写所有必填字段',
  all_agreements_not_met: '所有协议未达成',
  choose_employment_status: '请选择您的就业状态',
  enter_valid_tax_id: '请输入有效的税号',
  tax_id_error: '税号错误',
  only_english_characters: '请确保在提供的字段中仅输入英文字符。',
  birthday_invalid: '生日无效',
  minimum_age_error: '您需要至少 18 岁',
  error: '错误',
  submission_error: '提交您的信息时出现错误。请仔细检查提交的信息并重试。',
  documents_uploaded_successfully: '文件上传成功！我们正在处理它们以进行验证。',
  status_update_in_progress: '您的账户状态正在更新。请稍后返回查看更改。',
  upload_error: '上传过程中出现问题。请检查您的文件并重试。',
  upload_failed: '上传失败',
  upload_successful: '上传成功',
  drag_and_drop_files: '拖放文件到此处上传',
  click_here_to_upload: '点击此处上传',
  select_document_type: '选择文件类型',
  upload_additional_documents: '上传其他文件',
  seamless_transfer_of_your_investments_is_here: '无缝转移您的投资已到',
  acat_automated_customer_account_transfer:
    'ACAT（自动客户账户转移）是一种安全系统，可让您轻松将证券——如股票和ETF——从一个经纪公司转移到另一个，而无需出售您的投资。\n\n随着Investall从DriveWealth转移到我们的新合作伙伴Alpaca，ACAT确保您的资产能够快速、安全且无中断地转移。这样，您可以保留当前的投资组合，并继续享受Investall的卓越功能。\n\n我们将全程指导您，如有需要，我们也随时为您提供帮助！',
  verify_your_identity: '验证您的身份',
  enter_your_ssn_or_tax_id_and_we_will_prepare_the_account_transfer_request_for_you_make_sure_you_use_the_same_ssn_or_tax_id_used_to_create_your_alpaca_account:
    '输入您的SSN或税号，我们将为您准备账户转移请求。（请确保使用创建Alpaca账户时所使用的相同SSN或税号）',
  transfer_in_kind_assets_to_alpaca_securities:
    '除非上文说明另有指示，请以实物方式将所有资产转入我在Alpaca Securities LLC的账户。我理解，如果我的账户中的任何资产无论是否会产生罚款而无法立即转移，则这些资产可能无法在适用法规要求的时间内转移。我理解，对于任何无法转移的资产，将由交付和/或接收公司与我联系。我授权您清算我账户中任何不可转移的专有货币市场基金资产，并将由此产生的信用余额转入Alpaca Securities LLC。我授权转移方从我账户中的信用余额中扣除任何未结清的费用。如果我的账户不包含信用余额，或账户中的信用余额不足以清算我账户中的资产以满足该义务，我理解，在收到本转移指示的副本后，对于完整账户转移，转移方将冻结我的账户并取消您账面上我的所有未平仓订单。我确认我已销毁或退还给转移方与我的账户相关的所有信用/借记卡和/或未使用的支票。',
  please_make_sure_disclaimer_and_tax_id_is_properly_entered:
    '请确保免责声明和税号已正确输入',
  error_processing_request: '在处理您的请求时出错。请再试一次。',
  acat_submission_error: 'ACAT提交错误',
  your_account_transfer_is_in_progress_we_will_let_you_know_about_any_updates:
    '您的账户转移正在进行中。我们会及时通知您任何更新。',
  transfer_your_accounts: '转移您的账户',
  password_attempts_exceeded_please_try_again_in_10_minutes:
    '密码尝试次数超限。请10分钟后再试。',
}
