import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'
import Endpoint from '../Endpoint'

export default function NewAccountForAlpaca(props) {
  const toastErrorMsg = useRef()

  const [prefillData, setPrefillData] = useState({})
  const [userForm, setUserForm] = useState({
    smart_investing: 1,
  })
  const [loading, setLoading] = useState(false)

  const yesOrNo = [
    { label: props?.t('yes'), value: 'yes' },
    { label: props?.t('no'), value: 'no' },
  ]

  useEffect(() => {
    fetchSubmittedPrefinraData()
  }, [])

  const fetchSubmittedPrefinraData = async () => {
    try {
      let { data } = await axios.get(
        Endpoint + '/mobile/alpaca/account/checkpoint/fetch',
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )
      console.log('[PrefinraQuestions] prefill data', data)
      setPrefillData(data.output ?? {})
    } catch (error) {
      console.log('Failed to fetch prefill data', error)
      setPrefillData({})
    }
  }

  const parseBirthday = (birthday) => {
    const date = new Date(birthday)
    handleBirthdayInputChange('birthday', birthday)
    handleBirthdayInputChange('birthdayDay', date.getDate())
    handleBirthdayInputChange('birthdayMonth', date.getMonth() + 1)
    handleBirthdayInputChange('birthdayYear', date.getFullYear())
  }
  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    )
  }

  // year template for the prime react ui calander
  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className='p-ml-2'
        style={{ lineHeight: 1 }}
      />
    )
  }
  const handleBirthdayInputChange = (name, value) => {
    setUserForm((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleUserFormInputChange = (event) => {
    let { name, value } = event.target

    value = value?.replace(/[^A-Za-z0-9\s]/g, '')

    setUserForm((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleUsTaxPayerChange = (event) => {
    console.log('event', event)
    setUserForm((prevData) => ({
      ...prevData,
      ustaxpayer: event,
    }))
  }

  const isAtLeast18YearsOld = (year, month, day) => {
    const currentDate = new Date()
    const inputDate = new Date(year, month - 1, day) // JavaScript months are 0-based (0 = January, 1 = February, etc.)

    const ageDifferenceInMilliseconds = currentDate - inputDate
    const ageInYears =
      ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)

    return ageInYears >= 18
  }

  const createErrorToast = (error_title, error_msg) => {
    toastErrorMsg.current.show({
      severity: 'error',
      summary: `${error_title}`,
      detail: error_msg,
      life: 3000,
    })
  }

  const validateForm = () => {
    let {
      first_name,
      last_name,
      birthdayDay,
      birthdayMonth,
      birthdayYear,
      phone_no,
      ustaxpayer,
    } = userForm

    // if (userInfo?.phone && !phone_no) phone_no = userInfo?.phone

    // step 1 form validateion for empty fields
    if (
      !first_name ||
      first_name?.trim() === '' ||
      !last_name ||
      last_name?.trim() === '' ||
      !birthdayDay ||
      !birthdayMonth ||
      !birthdayYear ||
      !phone_no ||
      !ustaxpayer
    ) {
      createErrorToast(
        props?.t('incompleted'),
        props?.t('please_make_sure_required_fields_filled')
      )
      return false
    }

    if (phone_no.length < 7) {
      createErrorToast(
        props?.t('incompleted'),
        props?.t('please_make_sure_required_fields_filled')
      )
    }

    // not age 18 or older
    if (!isAtLeast18YearsOld(birthdayYear, birthdayMonth, birthdayDay)) {
      createErrorToast(
        props?.t('birthday_invalid'),
        props?.t('minimum_age_error')
      )
      return false
    }

    return true
  }

  const storePrefinraAnswers = async () => {
    let body = {
      first_name: userForm?.first_name,
      last_name: userForm?.last_name,
      // phone_number: userInfo?.phone
      //   ? userInfo?.phone
      //   : `+${userForm?.phone_no?.replace(/\D/g, '')}`,
      phone_number: `${userForm?.phone_no?.replace(/\D/g, '')}`,
      smart_investing: userForm?.smart_investing,
      birth_day: userForm?.birthdayDay?.toString(),
      birth_month: userForm?.birthdayMonth?.toString(),
      birth_year: userForm?.birthdayYear?.toString(),
      ustaxpayer: userForm?.ustaxpayer === 'yes' ? true : false,
    }

    console.log(body)

    try {
      let res = await axios.post(`${Endpoint}/mobile/prefinra-data/add`, body, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log('[api response] - prefinra-data/add ', res?.data)

      // if taxpayer is non us user show the ibquestionnaire
      // if (res?.data?.status && userForm?.ustaxpayer === 'no') {
      //   props?.setShowIBQuestionnaire()
      // }
      if (props?.addDataForAlpacaMigration) props?.setShowIBQuestionnaire()
    } catch (error) {
      console.log(error)
    }
  }
  const submitCheckpointData = async () => {
    try {
      let body = {
        ...prefillData,
        dob_day: userForm.birthdayDay?.toString()?.padStart(2, '0'),
        dob_month: userForm.birthdayMonth?.toString()?.padStart(2, '0'),
        dob_year: userForm.birthdayYear,
        ustaxpayer: userForm.ustaxpayer === 'yes' ? true : false,
        first_name: userForm.first_name,
        last_name: userForm.last_name,
        phone: userForm.phone_no,
      }
      console.log('body', body)
      let { data } = await axios.post(
        Endpoint + '/mobile/alpaca/account/checkpoint/save',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )
      console.log(data)
    } catch (error) {}
  }

  const submitAlpacaInfo = async () => {
    try {
      setLoading(true)
      await submitCheckpointData()
      await storePrefinraAnswers()
      await props?.getSavedInformation()
    } catch (error) {
      createErrorToast(props?.t('error'), props?.t('submission_error'))
      return false
    } finally {
      setLoading(false)
    }
  }

  const FooterContent = () => {
    return (
      <div className='user-info-dialog-footer-content'>
        <Button
          label={props?.t('continue')}
          onClick={async () => {
            if (validateForm()) {
              console.log('submit new user information', userForm)
              await submitAlpacaInfo()
            }
          }}
          loading={loading}
        />
      </div>
    )
  }
  return (
    <Dialog
      visible={props?.showModal}
      draggable={false}
      // header={props?.t('alpacaaccountmigration')}
      header={
        <div className='unremoveable-header-container'>
          <p className='unremoveable-header'>
            {props?.t('alpacaaccountmigration')}
          </p>

          <p
            onClick={() => {
              props?.signOut()
            }}
            className='unremoveable-signout'
          >
            {props?.t('signout')}
          </p>
        </div>
      }
      className='alpaca-migration-modal'
      closable={false}
      footer={FooterContent}
    >
      <div className='user-info-container'>
        <div className='input-container'>
          <label htmlFor='first_name'>{props?.t('whatsyourname')}</label>
          <InputText
            id='first_name'
            name='first_name'
            value={userForm?.first_name}
            placeholder={props?.t('firstname')}
            onChange={handleUserFormInputChange}
            style={{ marginBottom: '1rem' }}
          />
          <InputText
            id='last_name'
            name='last_name'
            placeholder={props?.t('lastname')}
            value={userForm?.last_name}
            onChange={handleUserFormInputChange}
          />
        </div>

        <div className='input-container'>
          <label>{props?.t('whatisyourdateofbirth')}</label>
          <Calendar
            value={userForm?.birthday}
            onChange={(e) => parseBirthday(e.target.value)}
            dateFormat='mm/dd/yy'
            name='birthday'
            showIcon
            icon='pi pi-calendar'
            placeholder='01/10/1990'
            monthNavigator={true}
            yearNavigator={true}
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            yearRange={`1900:${new Date().getFullYear()}`}
            className='calendar-input'
          ></Calendar>
        </div>

        <div className='input-container'>
          <label htmlFor='phone'>{props?.t('whatisyourphonenumber')}</label>

          <PhoneInput
            className='phone-input'
            id='phone'
            name='phone_no'
            value={userForm?.phone_no}
            onChange={(e) => {
              setUserForm((prevData) => ({
                ...prevData,
                phone_no: '+' + e,
              }))
            }}
            country='us'
          />
        </div>
        <div className='input-container'>
          <label>{props?.t('areyouaustaxpayer')}</label>
          <Dropdown
            value={userForm?.ustaxpayer}
            options={yesOrNo}
            onChange={(e) => handleUsTaxPayerChange(e.value)}
            style={{ lineHeight: 1 }}
          />
        </div>

        <Toast ref={toastErrorMsg} position='top-right' />
      </div>
    </Dialog>
  )
}
