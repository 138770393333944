import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useRef, useState } from 'react'
import '../../scss/components/AcatDialog.scss'
import { BiChevronLeft } from 'react-icons/bi'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { Toast } from 'primereact/toast'
import Axios from 'axios'

export default function AcatDialog(props) {
  const [step, setStep] = useState(0)
  const [agreement, setAgreement] = useState(false)
  const [idInput, setIdInput] = useState('')

  const toastErrorMsg = useRef()

  const beginAcat = async () => {
    try {
      // first validate
      if (!idInput || !agreement) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'ACAT Submission Error',
          detail: props.t(
            'please_make_sure_disclaimer_and_tax_id_is_properly_entered'
          ),
          life: 3000,
        })
        return
      }

      let body = {
        account_id: 'alpaca',
        ssn: idInput,
      }

      const res = await Axios.post(
        'https://api.allocaterite.com/mobile/alpaca/acats/initiate',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + props.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      if (res?.status) {
        setStep(2)
      } else {
        throw new Error('ACAT Initiate Error')
      }
        
    } catch (error) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: props.t('acat_submission_error'),
        detail: props.t('error_processing_request'),
        life: 3000,
      })
    }
  }

  return (
    <Dialog
      visible={props.visible}
      header={
        <p style={{ marginLeft: '40px', fontSize: 18 }}>
          {props.t('seamless_transfer_of_your_investments_is_here')}
        </p>
      }
      onHide={() => props.hide()}
      style={{ width: props.isSmallScreen ? '90vw' : '30vw' }}
    >
      {step === 2 ? (
        <div className='acat-form-container'>
          <p style={{ whiteSpace: 'pre-line', marginBottom: 16 }}>
            {props.t(
              'your_account_transfer_is_in_progress_we_will_let_you_know_about_any_updates'
            )}
          </p>
          <Button onClick={() => window.location.reload()}>Continue</Button>
        </div>
      ) : step === 1 ? (
        <div className='acat-form-container'>
          <button onClick={() => setStep(0)} className='back-btn'>
            <BiChevronLeft /> Back
          </button>
          <p style={{ fontSize: 24 }}>{props.t('verify_your_identity')}</p>
          <p style={{ textAlign: 'center', width: '80%' }}>
            {props.t(
              'enter_your_ssn_or_tax_id_and_we_will_prepare_the_account_transfer_request_for_you_make_sure_you_use_the_same_ssn_or_tax_id_used_to_create_your_alpaca_account'
            )}
          </p>
          <InputText onChange={(e) => setIdInput(e.target.value)} />
          <div className='disclaimer-checkbox-container'>
            <Checkbox
              onChange={() => setAgreement(!agreement)}
              checked={agreement}
            />
            <p>{props.t('transfer_in_kind_assets_to_alpaca_securities')}</p>
          </div>
          <Button onClick={() => beginAcat()}>Submit</Button>
        </div>
      ) : (
        <div className='acat-form-container'>
          <p style={{ whiteSpace: 'pre-line', marginBottom: 16 }}>
            {props.t('acat_automated_customer_account_transfer')}
          </p>
          <Button onClick={() => setStep(1)}>Continue</Button>
        </div>
      )}
      <Toast ref={toastErrorMsg} position='top-right' />
    </Dialog>
  )
}
